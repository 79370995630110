import * as Yup from "yup";
import dayjs from 'dayjs';
import { Typography } from "@mui/material";

export const initialValues = (data) => {
  return {
    ...data,
    gender: data?.gender == 'male' ? 1 : 2,
    national_id_front: data?.national_id_media && data?.national_id_media[0]?.url,
    national_id_back: data?.national_id_media && data?.national_id_media[1]?.url,
    criminal_status_front: data?.criminal_status_media && data?.criminal_status_media[0]?.url,
    criminal_status_back: data?.criminal_status_media && data?.criminal_status_media[1]?.url,
    driver_licence_front: data?.driver_licence_media && data?.driver_licence_media[0]?.url,
    driver_licence_back: data?.driver_licence_media && data?.driver_licence_media[1]?.url,
    vehicle_licence_front: data?.vehicle_licence_media && data?.vehicle_licence_media[0]?.url,
    vehicle_licence_back: data?.vehicle_licence_media && data?.vehicle_licence_media[1]?.url,
    // birthdate: dayjs(data?.birthdate).format('DD-MM-YYYY')
    // birthdate: dayjs(new Date()).format("DD-MM-YYYY")
    birthdate: dayjs(data?.birthdate)
  };
};
export const structure = ({ cities, countries,options }) => [
  {
    head: "",
    width: "30%",
    list: [
      {
        name: `avatar`,
        kind: "upload",
        type: "image",
        label: "Profile Image",
        text: "JPG and PNG  file size no more than ",
        width: "100%",
        id: 0
      },
    ]
  },
  {
    head: "Personal details",
    width: "100%",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "Driver name*",
        width: "47%",
        placeholder: "Write driver name",
      },
      {
        name: `national_id`,
        kind: "input",
        type: "text",
        label: "National Id*",
        width: "47%",
        placeholder: "Write national Id",
      },
      {
        name: `email`,
        kind: "input",
        type: "text",
        label: "Email",
        width: "47%",
        placeholder: "Write Email",
      },
      {
        name: `phone_number`,
        kind: "input",
        type: "text",
        label: "Phone Number*",
        width: "47%",
        placeholder: "Write Phone Number",
        StartAdornment: <Typography>+966</Typography>
      },
      {
        name: `country_id`,
        kind: "select",
        type: "text",
        label: "Choose Country*",
        width: "47%",
        placeholder: "Choose Country",
        // options: countries,
        ...options?.countries
      },
      {
        name: `city_id`,
        kind: "select",
        type: "text",
        label: "Choose City*",
        width: "47%",
        placeholder: "Choose City",
        // options: cities,
        ...options?.cities
      },
      {
        name: `password`,
        kind: "input",
        type: "password",
        label: "Password*",
        width: "47%",
        placeholder: 'Write Password'
      },
      {
        name: `password_confirmation`,
        kind: "input",
        type: "password",
        label: "Confirm Password*",
        width: "47%",
        placeholder: 'Confirm Password'
      },
      {
        name: `birthdate`,
        kind: "date",
        type: "text",
        label: "Birthdate",
        width: "47%",
        placeholder: "Write birthdate",
        sx: { bgcolor: '#09c' }
      },
      {
        name: `gender`,
        kind: "radioBox",
        type: "text",
        label: "Select Gender",
        width: "47%",
        placeholder: "Choose City",
        options: [
          { label: "Male", value: 1 },
          { label: "Female", value: 2 },
        ]
      },
    ],
  },
  {
    head: "Upload Photos",
    width: "100%",
    list: [
      {
        name: 'national_id_front',
        kind: "upload",
        type: "image",
        label: "National ID Photos ( Front )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 1
      },
      {
        name: `national_id_back`,
        kind: "upload",
        type: "image",
        label: "National ID Photos ( Back )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 2
      },
      {
        name: `criminal_status_front`,
        kind: "upload",
        type: "image",
        label: "Criminal status ( Front )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 3
      },
      {
        name: `criminal_status_back`,
        kind: "upload",
        type: "image",
        label: "Criminal status ( Back )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 4
      },
      {
        name: `driver_licence_front`,
        kind: "upload",
        type: "image",
        label: "Driver License Photos ( Front )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 5
      },
      {
        name: `driver_licence_back`,
        kind: "upload",
        type: "image",
        label: "Driver License Photos ( Back )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 6
      },
      {
        name: `vehicle_licence_front`,
        kind: "upload",
        type: "image",
        label: "Vehicle License Photos ( Front )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 7
      },
      {
        name: `vehicle_licence_back`,
        kind: "upload",
        type: "image",
        label: "Vehicle License Photos ( Back )*",
        text: "JPG and PNG  file size no more than",
        width: "47%",
        id: 8
      },
    ],

  },
];

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const validationSchema = (formikValues, id) =>
  Yup.object({
    avatar: Yup.string(),
    national_id_front: Yup.string().required('Required'),
    national_id_back: Yup.string().required('Required'),
    criminal_status_front: Yup.string().required("required"),
    criminal_status_back: Yup.string().required("required"),
    driver_licence_front: Yup.string().required("required"),
    driver_licence_back: Yup.string().required("required"),
    vehicle_licence_front: Yup.string().required("required"),
    vehicle_licence_back: Yup.string().required("required"),
    birthdate: Yup.string(),
    gender: Yup.number(),
    city_id: Yup.number().required("required"),
    country_id: Yup.number().required("required"),
    phone_number: Yup.string().required("required"),
    name: Yup.string().required("required"),
    national_id: Yup.string()
      .required("National ID is required")
      .test('is-length-16', 'National ID must be exactly 16 characters', (value) => {
        return value.length === 16;
      }),
    email: Yup.string().matches(emailRegex, 'Email is not valid'),
    password: (formikValues?.password_confirmation || !id) ? Yup.string().required("required").min(6, "password can be at least 6 characters") : Yup.string().min(6, "password can be at least 6 characters"),
    password_confirmation: (formikValues?.password || !id) ? Yup.string()
      .required('required')
      .oneOf([Yup.ref("password"), null], `Passwords don't match`) : Yup.string()
        .oneOf([Yup.ref("password"), null], `Passwords don't match`),
  });
