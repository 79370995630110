import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import SelectOptions from "../../Forms/main/components/CSelect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleMap from "../../../../Molecules/Map/GoogleMap";
import Model from "../../../Model/Model";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useThemePalette } from "../../../../../common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
import { geocode, RequestType, setKey } from "react-geocode";
import { GoogleMapKey } from "../../../../../services/modules/utils/constant";
import TextFieldInput from "../../../Input/TextField/TextFieldInput";
import { useLocation } from "react-router-dom";

export default function LocationModel({
  data,
  id,
  formik,
  openLocationModal,
  setOpenLocationModal,
  setLocationData,
  getCities,
}) {
  const cities = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const cititesLoading = useSelector((state) => state.cities.load);
  const [mapZoom, setMapZoom] = useState(10);
  const { pathname } = useLocation();

  const { orange, oliveGreen, red } = useThemePalette();
  const [address, setAddress] = useState("");
  // const [location, setLocation] = useState({
  //   lat: 24.7136,
  //   lng: 46.6753,
  // });
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [location, setLocation] = useState({ lat: null, lng: null });
  // const [address, setAddress] = useState('');
  // const [isUserSearch, setIsUserSearch] = useState(false);

  const getCurrentLocation = (lat, lng) => {
    setLocation({ lat, lng });
    formik.setValues((prevValues) => ({
      ...prevValues,
      lat: lat,
      long: lng,
      detailed_address: address || "", // Clear the address initially
    }));
  };

  useEffect(() => {
    if (location.lat && location.lng) {
      geocode(RequestType.LATLNG, `${location.lat},${location.lng}`)
        .then(({ results }) => {
          let addr = results[0].formatted_address;
          setAddress(addr);
          formik.setValues((prevValues) => ({
            ...prevValues,
            detailed_address: addr,
          }));
          // if (isUserSearch) {
          //   setMapZoom(14);
          //   setIsUserSearch(false); // Reset after zooming in
          // }
        })
        .catch(console.error);
    }
  }, [location]);

  // Ensure the GoogleMapKey is set properly
  useEffect(() => {
    setKey(GoogleMapKey); // Set your API key here
  }, [GoogleMapKey]);

  const handleSave = () => {
    // Check if city and detailed address are present
    if (!formik?.values?.google_city_id) {
      setCityError(true);
    } else {
      setCityError(false);
    }

    if (!formik?.values?.location) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    // If both city and detailed address are present, proceed with saving
    if (formik?.values?.google_city_id && formik?.values?.location) {
      formik.handleSubmit();
    }
  };

  const determineType = () => {
    let type;
    if (pathname == "/vendor-registration") {
      type = !data ? "add" : "edit";
    } else {
      type = !id ? "add" : "edit";
    }
    return type;
  };
  return (
    <Model
      openVar={openLocationModal}
      title={"Add Location"}
      titleStyle={{ fontSize: 32, fontWeight: 500, ml: 3 }}
      closeFunc={() => {
        setOpenLocationModal(false);
        formik?.resetForm();
        setLocationData({ lat: 24.7136, long: 46.6753 });
      }}
    >
      <Stack
        gap={1}
        onSubmit={formik.handleSubmit}
        component="form"
        width="100%"
      >
        <Typography fontSize={18} fontWeight={400}>
          City
        </Typography>
        <SelectOptions
          IconComponent={KeyboardArrowDownIcon}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formik?.values?.google_city_id}
          name={"google_city_id"}
          options={[data?.google_city, ...cities].filter((item) => item)}
          getList={getCities}
          count={cititesCount}
          isLoading={cititesLoading?.loading}
          onOpen={cities?.length <= 1 && getCities}
          formik={formik}
          changeAction={(value) => {
            const city = cities.find((city) => city.id === value);
            formik.setValues({
              ...formik?.values,
              google_city_id: value,
              city: city.name,
            });
          }}
          sx={{
            "& .MuiFormLabel-root-MuiInputLabel-root": {
              display: "none !important",
            },
          }}
        />
        {cityError && <Typography color="red">City is required</Typography>}

        <Typography fontSize={18} fontWeight={400}>
          Detailed Address
        </Typography>
        <TextFieldInput
          placeholder={"Write detailed address "}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type={"text"}
          value={formik?.values?.location}
          multiline
          rows={5}
          name={"location"}
          fullWidth
        />
        {addressError && (
          <Typography color="red">Detailed Address is required</Typography>
        )}

        <GoogleMap
          getCurrentLocation={getCurrentLocation}
          location={location || { lat: 24.7136, lng: 46.6753 }}
          type={determineType()}
          details={data}
          // zoom={mapZoom}
          // setIsUserSearch={setIsUserSearch}
        />

        <Stack flexDirection={"row"} justifyContent={"center"}>
          <Stack
            flexDirection={"row"}
            p={1}
            sx={{
              backgroundColor: "white",
              textAlign: "center",
              position: "relative",
              bottom: 90,
              width: "80%",
            }}
          >
            <img
              src={icons.location}
              alt={"location"}
              style={{ alignItems: "start" }}
            />
            <Typography>{address}</Typography>
          </Stack>
        </Stack>

        <Stack flexDirection={"row"} justifyContent={"center"} mt={2} gap={5}>
          <Button
            variant={"outlined"}
            sx={{
              width: "5%",
              color: red,
              borderColor: red,
              "&:hover": {
                color: red,
                borderColor: red,
                bgColor: "transparent",
              },
            }}
            onClick={() => setOpenLocationModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            sx={{
              width: "5%",
              background: oliveGreen,
              "&:hover": { background: oliveGreen },
            }}
            type={"submit"}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Model>
  );
}
