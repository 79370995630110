import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
    dimensions: data?.dimensions?.toLowerCase() == 'three' ? 2 : 1
  }
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `image`,
        kind: "upload",
        type: 'image',
        label: "Wrap Photo*",
        placeholder: 'test',
        text: 'JPG and PNG  file size no more than',
        width: "47%",
      },
      {
        width: "47%",
      },
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Wrap Name(EN)*",
        width: "47%",
        placeholder: "Wrap Name (EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Wrap Name(AR)",
        width: "47%",
        placeholder: "Wrap Name(AR)"
      },
      {
        name: `dimensions`,
        kind: "radioBox",
        label: "Dimentions",
        width: "47%",
        options: [
          { label: "Two(2)", value: 1 },
          { label: "Three(3)", value: 2 },
        ],
      },
    ],
  },
];


export const validationSchema = () =>

  Yup.object({
    image: Yup.string().required('Required'),
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
      name: Yup.string(),
    }),
    dimensions: Yup.number(),
  });