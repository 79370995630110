import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    englishTagName:data?.localized_data?.en?.name,
    arabicTagName:data?.localized_data?.ar?.name
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `englishTagName`,
        kind: "input",
        type: "text",
        label: "English Tag Name*",
        width: "47%",
      },
      {
        name: `arabicTagName`,
        kind: "input",
        type: "text",
        label: "Arabic Tag Name*",
        width: "47%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    englishTagName: Yup.string().required("required"),
    arabicTagName: Yup.string().required("required"),
  });
