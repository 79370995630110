import * as Yup from "yup";
export const initialValues = (data) => {
    return {
        ...data,
        englishOptionType:data?.localized_data?.en?.name,
        arabicOptionType:data?.localized_data?.ar?.name
    };
};
export const structure = () => [
    {
        head: "",
        width: "100%",
        list: [
            {
                name: `englishOptionType`,
                kind: "input",
                type: "text",
                label: "Option Type(EN)*",
                width: "47%",
            },
            {
                name: `arabicOptionType`,
                kind: "input",
                type: "text",
                label: "Option Type(AR)*",
                width: "47%",
            },
        ],
    },
];

export const validationSchema = () =>
    Yup.object({
        englishOptionType: Yup.string().required("required"),
        arabicOptionType: Yup.string().required("required"),
    });
