import * as Yup from "yup";
export const initialValues = (data) => {
  console.log(data);
  return {
    ...data,
    ...data?.localized_data,
    google_city_id: data?.google_city?.id,
  };
};
export const structure = (options, cities) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `google_city_id`,
        kind: "select",
        type: "text",
        label: "City name*",
        width: "47%",
        placeholder: "Write the city name",
        ...options?.cities
      },
      {
        name: `radius`,
        kind: "input",
        type: "number",
        label: "Radius*",
        width: "47%",
        EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>KM</p>,
        placeholder: "Radius",
      },
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Zone name(EN)*",
        width: "47%",
        placeholder: "Write the zone name",
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Zone name(AR)*",
        width: "47%",
        placeholder: "Write the zone name",
      },
      {
        name: `delivery_fees`,
        kind: "input",
        type: "number",
        label: "Delivery fees",
        width: "47%",
        EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
        placeholder: "Write Delivery fees",
      },
      {
        name: `is_fulfillment_center`,
        kind: "checkbox",
        type: "text",
        label: "Fulfillment Center",
        width: "47%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    google_city_id: Yup.string().required("required"),
    radius: Yup.number("please enter a valid number").required("required"),
    en: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    delivery_fees: Yup.number("please enter a valid number"),
    is_fulfillment_center: Yup.boolean(),
  });
