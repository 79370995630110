import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import NotificationsTemplate from "templates/push-notifications";
import {
  createPushNotificationRequest,
  getPushNotificationsRequest,
} from "services/modules/notifications/push-notifications";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

function PushNotificationsPage() {
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.pushNotifications.pushNotifications);
  const count = useSelector((state) => state.pushNotifications.count);
  const details = selectedRow?.rowData;
  const load = useSelector((state) => state.pushNotifications.load);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getPushNotificationsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      notificationEN: (
        <Typography>
          {data?.localized_data?.en?.message?.slice(0, 35)}
          {data?.localized_data?.en?.message?.length > 35 && "..."}
        </Typography>
      ),
      notificationAR: (
        <Typography>
          {data?.localized_data?.ar?.message?.slice(0, 35)}
          {data?.localized_data?.ar?.message?.length > 35 && "..."}
        </Typography>
      ),
      sent_at: dayjs(data?.sent_at).format("DD-MM-YYYY, hh:mm a"),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/push-notifications",
      title: "Push notifications",
    },
    type && {
      active: false,
      title: type === "details" ? "Notification details" : "Add notification",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/push-notifications");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Notification has been added successfully!`);
    };

    const requestData = {
      body: {
        notification: { ...data },
      },
      params: {
        user_type: "admin_user",
      },
      action,
    };
    dispatch(createPushNotificationRequest(requestData));
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
    ];
  };

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  return (
    <>
      <Loader open={load?.loading} />
      <NotificationsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
      />
    </>
  );
}

export default PushNotificationsPage;
