import { Typography } from "@mui/material";
import * as Yup from "yup";

export const initialValues = (data) => {
  const genders = {
    male: 1,
    female: 2,
    any: 3,
  };
  return {
    ...data,
    ...data?.localized_data,
    thumbnail: data?.thumbnail,
    images: data?.media?.map((img) => img.url),
    store_id: data?.store?.id,
    option_types: data?.option_types?.map((option) => {
      return {
        label: option?.name,
        value: option?.id,
      };
    }),
    occasion_types: data?.occasion_types_details?.map((option) => {
      return {
        label: option?.name,
        value: option?.id,
      };
    }),
    categories: data?.categories?.map((option) => {
      return {
        label: option?.name,
        value: option?.id,
      };
    }),
    target_gender: genders[data?.target_gender],
  };
};

export const structure = (options) => {
  return [
    {
      head: "",
      width: "100%",
      list: [
        {
          name: `thumbnail`,
          kind: "upload",
          type: "image",
          label: "Thumbnail",
          placeholder: "test",
          text: "JPG and PNG  file size no more than 1MB",
          width: "35%",
          id: '10'
        },
        { width: "47%" },
        {
          name: `images`,
          kind: "multiUpload",
          type: "text",
          label: "Images*",
          width: "100%",
        },
        // { width: "20%" },
      ],
    },
    {
      head: (
        <Typography fontSize="32px" fontWeight="400">
          Details
        </Typography>
      ),
      width: "100%",
      list: [
        {
          name: `store_id`,
          kind: "select",
          type: "text",
          label: "Vendor*",
          width: "47%",
          placeholder: "Choose vendor",
          height: "250px",
          ...options?.vendors,
        },
        {
          name: `is_visible`,
          kind: "checkbox",
          type: "text",
          label: "Active",
          width: "10%",
        },
        {
          name: `is_wrappable`,
          kind: "checkbox",
          type: "text",
          label: "Is wrappable",
          width: "20%",
        },
        {
          name: `en.name`,
          kind: "input",
          type: "text",
          label: "Product Name (EN)*",
          width: "47%",
          placeholder: "Write Product Name (EN) ",
        },
        {
          name: `ar.name`,
          kind: "input",
          type: "text",
          label: "Product Name (AR) ",
          width: "47%",
          placeholder: "Write Product Name (AR) ",
        },
        {
          name: `price`,
          kind: "input",
          type: "number",
          label: "Price*",
          width: "47%",
          placeholder: "Enter the price after discount",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
        },
        {
          name: `price_after_discount`,
          kind: "input",
          type: "number",
          label: "Price after discount",
          width: "47%",
          placeholder: "Enter the price after discount",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
        },
        {
          name: `occasion_types`, //arrau of occasion types ids
          kind: "multiSelect",
          type: "text",
          label: "Occasions",
          width: "47%",
          ...options?.occassions,
          placeholder: "Choose Occasion",
          height: "250px",
        },
        {
          name: `categories`, //array
          kind: "multiSelect",
          type: "text",
          label: "Category",
          width: "47%",
          placeholder: "Choose Category",
          ...options?.categories,
        },
        {
          name: `option_types`,
          kind: "multiSelect",
          type: "text",
          label: "Select Option type*",
          placeholder: "Choose Option type",
          width: "47%",
          ...options?.optionTypes,
        },
        {
          name: `target_age`,
          kind: "select",
          type: "text",
          label: "Select Age",
          width: "47%",
          placeholder: "Select Age ",
          options: [
            { id: "babies", name: "babies" },
            { id: "teenagers", name: "teenagers" },
            { id: "adults", name: "adults" },
            { id: "any", name: "any" },
          ],
        },
        {
          name: `en.description`,
          kind: "textArea",
          type: "text",
          label: "Description(EN)",
          width: "47%",
          placeholder: "Write Description(EN)",
          colSpan: 6,
        },
        {
          name: `ar.description`,
          kind: "textArea",
          type: "text",
          label: "Description(AR)",
          width: "47%",
          placeholder: "Write Description(AR)",
          colSpan: 6,
        },

        {
          name: `target_gender`,
          kind: "radioBox",
          type: "text",
          label: "Gender",
          width: "47%",
          placeholder: "Enter the price after discount",
          options: [
            { value: "1", label: "Male" },
            { value: "2", label: "Female" },
            { value: "3", label: "Any" },
          ],
        },
      ],
    },
  ];
};

export const validationSchema = () =>
  Yup.object({
    // thumbnail: Yup.string(),
    images: Yup.array().required("required"),
    store_id: Yup.string().required("required"),
    is_visible: Yup.boolean(),
    is_wrappable: Yup.boolean(),
    en: Yup.object().shape({
      name: Yup.string().trim().required("Required"),
    }),
    // ar: Yup.object().shape({
    //   name: Yup.string().trim(),
    //   description: Yup.string().trim(),
    // }),
    price: Yup.number("Invalid Price").required("required"),
    price_after_discount: Yup.number()
      .typeError("Invalid Price")
      .nullable()
      .test("is-greater", "Price After discount must be less than original price", function (value) {
        const { price } = this.parent;
        if (value == null) {
          return true; // Allow empty price_after_discount
        }
        return value <= price;
      }),

    option_types: Yup.array()
      .min(1, "Option Types required")
      .required("required"),
    target_age: Yup.string().min(1, "select age"),
    target_gender: Yup.string(),
  });
