import { Box, CircularProgress, Modal, Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";
import AddLocation from "components/Molecules/Products/Variants/addLocation/addLocation";
import FormHeader from "components/Molecules/Products/Variants/formHeader";
import VariantDetailsForm from "components/Molecules/Products/Variants/variantDetailsForm";
import CustomTabs from "components/Organisms/tabs/customTabs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { removeDuplicateObjects } from "services/modules/utils/Helper";
import setAuthToken from "services/modules/utils/handel_api";
import { initialValues, structure, validationSchema } from "templates/products/productVariant/sturcture";

export default function VariantForm({
  open,
  id,
  details,
  optionTypesFilter = [],
  handleSubmit,
  locations,
  setLocations,
  handleVariantFormClose,
  handleSaveVariantBranch,
  handleDeleteVariantBranch,
  branches,
  fetchOptionValues,
  option_values,
  option_values_loading,
}) {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const { oliveGreen } = useThemePalette();
  const load = useSelector((state) => state.products.variants_loading);

  const [selectedTab, setSelectedTab] = useState(0);
  const refsubmit = useRef();

  const style = {
    position: "absolute",
    top: selectedTab == 0 ? "50%" : "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    overflow: "hidden",
    minHeight: 500,
  };

  const formik = useFormik({
    initialValues: !id ? {} : initialValues(details, optionTypesFilter),
    validationSchema: validationSchema(optionTypesFilter),
    onSubmit: async (values) => {
      handleSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClose = () => {
    formik.resetForm();
    handleVariantFormClose();
    setSelectedTab(0);
  };

  const handleAddAnotherLocation = () => {
    setLocations([...locations, { city: { id: 0 }, count_on_hand: 0 }]);
  };

  const handleLocationDataChange = (i, key, value) => {
    const newLocations = locations.map((loc, index) => {
      if (i === index) {
        if (key == "city") {
          return {
            ...loc,
            city: {
              id: value,
            },
          };
        } else if (key == "quantity") {
          return {
            ...loc,
            count_on_hand: value,
          };
        }
      } else {
        return loc;
      }
    });
    setLocations(newLocations);
  };

  const prepareOptionTypes = () => {
    let optionTypes = [];

    optionTypes = optionTypesFilter?.map((option) => {
      let options = option_values[option?.id]?.list || [];
      let values = details?.option_values || [];
      if (id) {
        // for default values when editing
        //without this we will not have initial value for the Cselect to select from
        options = [...options, ...values?.filter((opt) => opt?.option_type_id == option.id)];
        // because we set initial values, and then fetch when we open the ddl
        //we have to remove duplicate options
        options = removeDuplicateObjects(options, "id");
      }

      return {
        name: option?.name,
        kind: "select",
        type: "text",
        label: `${option?.name}*`,
        placeholder: option?.name,
        width: "100%",
        options,
        isLoading: option_values_loading?.type === `option value ${option?.id}`,
        onOpen: () => {
          if (!option_values[option?.id] || option_values[option?.id]?.list?.length <= 1) {
            // console.log(option_values, id, option_values[id]?.list?.length <= 1, "TEST");
            fetchOptionValues({ page_number: 1 }, option?.id);
          }
        },
        height: "200px",
        count: option_values[option?.id]?.count,
        getList: (filterObj) => fetchOptionValues(filterObj, option?.id),
      };
    });

    return optionTypes;
  };

  const tabs = [
    {
      title: "Details",
      children: (
        <VariantDetailsForm
          handleClose={handleClose}
          formik={formik}
          refsubmit={refsubmit}
          optionTypesFilter={optionTypesFilter}
          structure={() => structure(prepareOptionTypes)}
        />
      ),
    },
    {
      title: "Locations",
      children: (
        <AddLocation
          locations={locations}
          setLocations={setLocations}
          handleAddAnotherLocation={handleAddAnotherLocation}
          handleLocationDataChange={handleLocationDataChange}
          handleSaveVariantBranch={handleSaveVariantBranch}
          handleDeleteVariantBranch={handleDeleteVariantBranch}
          branches={branches}
        />
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const msg = Object.keys(formik?.errors).map(
        (err) => `${err == "en" ? "English name" : err.split("_").join(" ")}  is required`
      );
      toast.error(msg?.join(", "));
    }
  }, [formik?.errors, formik?.isSubmitting]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add variant" aria-describedby="variant details">
      <Box sx={style}>
        <FormHeader handleClose={handleClose} title={id ? "Edit variant" : "Add variant"} />
        <Loading
          isLoading={
            load?.type == "variantsDetails" ||
            load?.type == "update" ||
            load?.type == "updateVariantBranch" ||
            load?.type == "deleteVariantBranch" ||
            load?.type == "createVariantBranch" ||
            load?.type == "createVariant" ||
            load?.type == "updateVariant"
          }
        >
          <CustomTabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            tabstyle={{
              width: "50%",
              fontWeight: "500",
            }}
            selectedTabStyle={{
              color: `${oliveGreen} !important`,
              borderBottom: "1px solid ",
              fontWeight: "600",
            }}
          />
        </Loading>
      </Box>
    </Modal>
  );
}
