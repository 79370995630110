import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    origin_zone_id: data?.origin_zone?.id,
    destination_zone_id: data?.destination_zone?.id,
    city_id: data?.city?.id
  }
};
export const structure = (options, cityId) => {
  console.log(options, 'options');
  return [
    {
      head: "",
      width: "100%",
      list: [
        {
          name: `city_id`,
          kind: "select",
          type: "text",
          label: "City name*",
          width: "47%",
          placeholder: 'Write the city name',
          ...options?.cities
          // onChange: (value) => handleCityChange(value)
        },
        {
          name: `origin_zone_id`,
          kind: "select",
          type: "text",
          label: "Origin zone*",
          width: "47%",
          placeholder: 'Write the city name',
          ...options?.zones,
          disabled: !cityId
        },
        {
          name: `destination_zone_id`,
          kind: "select",
          type: "text",
          label: "Destination zone*",
          width: "47%",
          placeholder: 'Write the city name',
          ...options?.zones,
          disabled: !cityId
        },
        {
          name: `delivery_fees`,
          kind: "input",
          type: "text",
          label: "Delivery fees*",
          width: "47%",
          EndAdornment: (
            <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
          ),
          placeholder: 'Write Delivery fees'
        },

      ],
    },
  ];
}

export const validationSchema = () =>
  Yup.object({
    city_id: Yup.string().required("required"),
    origin_zone_id: Yup.string().required("required"),
    destination_zone_id: Yup.string().required("required"),
    delivery_fees: Yup.number("please enter a valid number").required("required"),
  });
