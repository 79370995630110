import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import { getReturnOrderRequest } from "services/modules/orders/return-order";
import ReturnRequestTemplate from "templates/orders/return-order/returnRequestTemplate";
import { getOrderStatusRequest } from "services/modules/orders";
function ReturnRequestsPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let [searchParams] = useSearchParams();

  const type = searchParams.get("type");
  const list = useSelector((state) => state.returnOrders.returnRequestList);
  const count = useSelector((state) => state.returnOrders.count);
  const orderStatus = useSelector((state) => state.orders.orderStatus);
  const [filterObj, setFilterObj] = useState(null);
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const { load } = useSelector((state) => state?.returnOrders);
  const [page, setPage] = useState(1);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/orders",
      title: "orders",
    },
    {
      active: true,
      path: `/orders?type=details&id=${id}`,
      title: `Order #${id}`,
    },
    {
      active: !type ? false : true,
      path: "/return-request",
      title: "Return Requests ",
    },
  ];

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        sort_by: "id",
        order_id: id,
        ...filterObj,
      },
    };
    dispatch(getReturnOrderRequest(requestData));
  };
  useEffect(() => {
    getList(filterObj);
  }, [filterObj, id]);
  const getOrderStatus = async () => {
    dispatch(
      getOrderStatusRequest({
        id,
      })
    );
  };
  useEffect(() => {
    if (id) getOrderStatus();
  }, []);
  return (
    <>
      <Loader open={load?.loading && load?.type == "return"} />
      <ReturnRequestTemplate
        list={list}
        breadcrumbs={breadcrumbs}
        id={id}
        handlePageChange={handlePageChange}
        count={count}
        orderStatus={orderStatus}
        load={load}
      />
    </>
  );
}

export default ReturnRequestsPage;
