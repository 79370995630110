// import React from "react";
// import {
//   Backdrop,
//   Button,
//   ClickAwayListener,
//   MenuItem,
//   MenuList,
//   Paper,
//   Popper,
// } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
// import { Box } from "@mui/system";
// const CActions = ({
//   id,
//   index,
//   data,
//   handleClick,
//   handleClose,
//   anchorEl,
//   anchorRef,
//   open,
//   actionsList,
// }) => {
//   return (
//     <Box
//       style={{
//         position: "relative",
//         width: "fit-content",
//       }}
//     >
//       <Button
//         id="demo-positioned-button"
//         aria-controls={open ? "demo-positioned-menu" : undefined}
//         aria-haspopup="true"
//         aria-expanded={open ? "true" : undefined}
//         onClick={(e) => handleClick(e, index, data)}
//         sx={{
//           width: "15px !important",
//           display: "flex",
//           p: 0,
//           justifyContent: "start",
//         }}
//       >
//         <MoreVertIcon
//           className="moreMenu"
//           style={{
//             color: "#333132",
//             transform: "rotate(90deg)",
//           }}
//         />
//       </Button>

//       <Popper
//         open={anchorEl == index ? true : false}
//         anchorEl={anchorRef.current}
//         role={undefined}
//         transition
//         disablePortal
//         sx={{
//           minWidth: "100px",
//           "&": {
//             position: "absolute !important",
//             zIndex: "10",
//             top: "25px !important",
//             left: "-70px !important",
//           },
//         }}
//       >
//         <Backdrop
//           sx={{
//             color: "#fff",
//             backgroundColor: "transparent",
//             zIndex: (theme) => theme.zIndex.drawer + 1,
//           }}
//           open={anchorEl == index ? true : false}
//           onClick={handleClose}
//         ></Backdrop>
//         <Paper
//           sx={{
//             position: "relative",
//             zIndex: (theme) => theme.zIndex.drawer + 2,
//           }}
//         >
//           <ClickAwayListener onClickAway={handleClose}>
//             <MenuList
//               autoFocusItem={open}
//               id="composition-menu"
//               aria-labelledby="composition-button"
//             >
//               {actionsList({ id, index, data })?.map((action, index) => {
//                 return (
//                   action.condition(index) === true && (
//                     <MenuItem
//                       key={index}
//                       onClick={() => {
//                         action.click();
//                         handleClose();
//                       }}
//                     >
//                       {action.text}
//                     </MenuItem>
//                   )
//                 );
//               })}
//             </MenuList>
//           </ClickAwayListener>
//         </Paper>
//       </Popper>
//     </Box>
//   );
// };

// export default CActions;
import React from "react";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";

const CActions = ({ id, index, data, actionsList }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isActions = actionsList({ id, data }).length !== 0;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {isActions ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        "-"
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList
          autoFocusItem={open}
          sx={{
            p: '5px 10px',
          }}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          {actionsList({ id, index, data })?.map((action, index) => {
            return (
              action.condition(index) === true && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.click();
                    handleClose();
                  }}
                >
                  {action.text}
                </MenuItem>
              )
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default CActions;
